import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

//Components
import Layout from "../components/layout"
import Home from "../components/home"

const IndexPage = () => (
  <Layout>
    <Home/>
  </Layout>
)

export default IndexPage
